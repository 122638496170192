.App {
  text-align: center;
}

.Title {
  background-color: gray;
  width: 100%;
  display: flex;
  color: aliceblue;
  padding: 10px;
  text-align: left;
  font-size: 20px;
}

.UsernameBox {
  background-color: #282c34;
  color: aliceblue;
  padding: 10px;
}

.WhiteText {
  color:aliceblue;
}
.StatNameText{
  padding-left: 10%;
  text-align: left;
  color: aliceblue;
}
.StatText{
  padding-right: 10%;
  text-align: right;
  color: chocolate;
}

.EquNameText{
  padding-left: 4%;
  text-align: left;
  color: aliceblue;
}
.EquipText{
  text-align: left;
  color: chocolate;
}
.EquipInfoText{
  text-align: left;
  color: aliceblue;
}

.CutTap{
  background-color: #282c34;
  color: aliceblue;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
